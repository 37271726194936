<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
                <span class="title">
                    {{ $t('menu.favorites') }}
                </span>
            </div>
        </b-navbar>
        <div class="col-12 pr-2">
            <b-row class="nopads">
            <!-- Favorite items -->
            <div v-for="(item, index) in results" v-bind:key="index" class="col-xl-3 col-lg-4 col-md-6 nopads p-1">
                <transition name="fade">
                    <contract-favorite
                        v-if="item.type === FAV_TYPE_CONTRACT"
                        :id="item.id"
                        @shortcutClick="forwardShortcut"
                        @remove="removeFavorite(index)"
                    />
                </transition>
            </div>
            <!-- Add new favorite -->
            <div class="col-xl-3 col-lg-4 col-md-6 nopads p-1" @click="addNew = true">
                <transition name="fade">
                    <div class="favorite-container-empty">
                        <font-awesome-icon icon="plus"/>
                    </div>
                </transition>
            </div>
        </b-row>
        </div>
        <b-modal
            v-model="addNew"
            hide-header
            hide-footer
            hide-header-close
        >
            <favorites-selector
                @close="addNew = false"
                @newFavorite="addFavorite"/>
        </b-modal>

    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import ContractFavorite from "@/components/favorites/ContractFavorite";
import FavoritesSelector from "@/components/favorites/FavoritesSelector";

export default {
    name: 'Favorites',
    components: {FavoritesSelector, ContractFavorite},
    mixins: [restApi],
    data: function () {
        return {
            FAV_TYPE_CONTRACT: 1,
            results: [],
            addNew: false
        }
    },
    mounted: function () {
        this.fetchFavorites()
    },
    methods: {

        async fetchFavorites() {
            const response = await this.fetchUserPreferences('favorites');
            if (response) {
                if (response.data && response.data.preferences) {
                    this.results = response.data.preferences
                    return
                }
            }
            // TODO - Inform error!
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },

        removeFavorite(index) {
            this.results.splice(index, 1)
            this.saveFavorites()
        },

        forwardShortcut(view, item) {
            this.$emit('shortcutClicked', view, item)
        },

        addFavorite(type, id) {
            this.results.push(
                {
                    type: type,
                    id: id
                }
            )
            this.saveFavorites()
            this.addNew = false
        },

        async saveFavorites() {
            return await this.upsertUserPreferences('favorites', this.results);
        },

    }
}
</script>
